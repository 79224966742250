import React, { useState } from "react";
import styled from "styled-components";
import { SiWire } from "react-icons/si";
import { BiBracket } from "react-icons/bi";
import { FcNumericalSorting12, FcList } from "react-icons/fc";
import { ImTree } from "react-icons/im";
import { BiCodeAlt } from "react-icons/bi";
import { HiOutlineMenuAlt1 } from "react-icons/hi";
import { GoGraph } from "react-icons/go";
import { BsViewStacked, BsArrowRepeat, BsSearch } from "react-icons/bs";
import { GrStackOverflow } from "react-icons/gr";
import Tooltip from "@material-ui/core/Tooltip";
import { useEffect } from "react";
import { db } from "../../firebase";

function Question() {
  const [question, setQuestion] = useState([]);
  const [categorySelected, setCategorySelected] = useState("Arrays");
  useEffect(async () => {
    const qsref = db.collection("questions");
    const snapshot = await qsref.orderBy("category", "asc").get();
    setQuestion(snapshot.docs);
  }, []);

  const icons = (c) => {
    switch (c) {
      case "Strings":
        return <SiWire />;
      case "Arrays":
        return <BiBracket />;
      case "Sorting":
        return <FcNumericalSorting12 />;
      case "Trees":
        return <ImTree />;
      case "Dynamic Programming":
        return <BiCodeAlt />;
      case "Algorithms":
        return <HiOutlineMenuAlt1 />;
      case "Graphs":
        return <GoGraph />;
      case "Heaps":
        return <BsViewStacked />;
      case "Linked Lists":
        return <FcList />;
      case "Recursion":
        return <BsArrowRepeat />;
      case "Search":
        return <BsSearch />;
      case "Stack":
        return <GrStackOverflow />;
      default:
        break;
    }
  };
  return (
    <Container>
      <Wrapper>
        <InnerWrapper>
          <ButtonWrapper>
          <CategoryButton>
          <button onClick={(e)=>{e.preventDefault();setCategorySelected("Strings")}}  className="readmore">Strings</button>
          </CategoryButton>
          <CategoryButton>
          <button onClick={(e)=>{e.preventDefault();setCategorySelected("Arrays")}} className="readmore">Arrays</button>
          </CategoryButton>
          <CategoryButton>
          <button onClick={(e)=>{e.preventDefault();setCategorySelected("Sorting")}} className="readmore">Sorting</button>
          </CategoryButton>
          <CategoryButton>
          <button onClick={(e)=>{e.preventDefault();setCategorySelected("Trees")}} className="readmore">Trees</button>
          </CategoryButton>
          <CategoryButton>
          <button onClick={(e)=>{e.preventDefault();setCategorySelected("Dynamic Programming")}} className="readmore">Dynamic Programming</button>
          </CategoryButton>
          <CategoryButton>
          <button onClick={(e)=>{e.preventDefault();setCategorySelected("Algorithms")}} className="readmore">Algorithms</button>
          </CategoryButton>
          <CategoryButton>
          <button onClick={(e)=>{e.preventDefault();setCategorySelected("Graphs")}} className="readmore">Graphs</button>
          </CategoryButton>
          <CategoryButton>
          <button onClick={(e)=>{e.preventDefault();setCategorySelected("Heaps")}} className="readmore">Heaps</button>
          </CategoryButton>
          <CategoryButton>
          <button onClick={(e)=>{e.preventDefault();setCategorySelected("Linked Lists")}} className="readmore">Linked Lists</button>
          </CategoryButton>
          <CategoryButton>
          <button onClick={(e)=>{e.preventDefault();setCategorySelected("Recursion")}} className="readmore">Recursion</button>
          </CategoryButton>
          <CategoryButton>
          <button onClick={(e)=>{e.preventDefault();setCategorySelected("Search")}} className="readmore">Search</button>
          </CategoryButton>
          <CategoryButton>
          <button onClick={(e)=>{e.preventDefault();setCategorySelected("Stack")}} className="readmore">Stack</button>
          </CategoryButton>
          <CategoryButton>
          <button onClick={(e)=>{e.preventDefault();setCategorySelected("Other")}} className="readmore">Other</button>
          </CategoryButton>
          
          </ButtonWrapper>
          {question.map((q) => {
           
            return (
              q.data().category===categorySelected?<ListItem key={q.id} href={`/questions/${q.id}`}>
                <h3>
                  <Tooltip title={q.data().author} >
                    <img src={q.data().img} alt="" />
                  </Tooltip>
                  <Tooltip title={q.data().title} >
                  <p> {q.data().title}</p>
                  </Tooltip>
                  
                </h3>
                <div>
                  {q.data().language}
                  <p>{q.data().category}</p>
                  <p>{q.data().level}</p>
                </div>
              </ListItem>:null
            );
          })}
        </InnerWrapper>
      </Wrapper>
    </Container>
  );
}

export default Question;

const Container = styled.div`
  min-height: 100vh;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
  margin: auto;
`;
const Wrapper = styled.div`
  width: 100vw;
`;
const InnerWrapper = styled.div`
  max-width: 1000px;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 152px;
  @media (max-width: 768px) {
    margin-top: 132px;
  }
`;

const ButtonWrapper=styled.div`
  display: flex;
  align-items:center;
  flex-wrap:wrap;
  justify-content: center;
`

const CategoryButton=styled.div`
  margin-top: 2rem;
  button {
    background: #81d1ff;
    border: none;
    padding: 0.6rem 1.1rem;
    color: #fff;
    border-radius: .5rem;
    /* box-shadow: 0px 13px 24px -7px #81d1ff; */
    transition: all 0.3s ease-in-out;
    margin: 0.5rem;
    margin-left: 0;
    font-size: 1rem;
    cursor: pointer;
    &:hover {
      box-shadow: 0px 17px 16px -11px #81d1ff;
      transform: translateY(-5px);
    }
  }
  .readmore {
    display:flex;
    align-items:center;
    color: #000;
    line-height:1rem;
    background: #fff;
    border: 3px solid #81d1ff;
    &:hover {
      box-shadow: 0px 17px 16px -11px #81d1ff;
      transform: translateY(-5px);
    }
  }

`

const ListItem = styled.a`
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  height: 50px;
  background-color: white;
  width: 80%;
  border-radius: 8px;
  margin: 15px;
  padding: 0 20px;
  transition: all 0.2s ease-in;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    transform: translateY(-20px);
  }

  img {
    border-radius: 50%;
    width: 25px;
    height:25px;
    object-fit: cover;
    margin-right: 1rem;
  }

  div {
    display: flex;
    align-items: center;
    width: 40%;
    justify-content: space-between;
  }

  h3 {
    display: flex;
    align-items: center;
  }

  @media (max-width: 768px) {
    h3 {
      font-size: 1rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 55%;

      img{
        display: none;
      }
    }



    div {
      p {
        font-size: 70%;
      }
    }
  }
`;

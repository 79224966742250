import React from "react";
import styled from "styled-components";
import {BsArrowRight} from 'react-icons/bs'
import { useHistory } from "react-router-dom";

const LandText = () => {
  let history = useHistory();

  function handleClick() {
    history.push("/questions");
  }

  return (
    <Container>
      <h5>Understand The Code</h5>
      <h1>Check.</h1>
      <h1>Analyse..</h1>
      <h1>Practice...</h1>
      <h5>Maestro Coding gives you Interview level coding problems and their codes for free. May be you can optimize them for better results. We will add new problems regularly.</h5>
      <BtnContainer>
        <button onClick={handleClick} className="readmore">Questions<BsArrowRight size="25" ></BsArrowRight></button>
      </BtnContainer>
    </Container>
  );
};

const BtnContainer = styled.div`
  margin-top: 2rem;
  button {
    background: #81d1ff;
    border: none;
    padding: 0.6rem 1.1rem;
    color: #fff;
    border-radius: 1rem;
    box-shadow: 0px 13px 24px -7px #81d1ff;
    transition: all 0.3s ease-in-out;
    margin: 0.5rem;
    margin-left: 0;
    font-size: 0.8rem;
    cursor: pointer;
    &:hover {
      box-shadow: 0px 17px 16px -11px #81d1ff;
      transform: translateY(-5px);
    }
  }
  .readmore {
    display:flex;
    align-items:center;
    color: #53A7EF;
    background: #fff;
    border: 3px solid #81d1ff;
    &:hover {
      box-shadow: 0px 17px 16px -11px #81d1ff;
      transform: translateY(-5px);
    }
  }
`;

const Container = styled.div`
  padding: 1rem;
  h5 {
    color: #515151;
    font-weight: 500;
    font-size: 0.9rem;
    margin-bottom: 1rem;
    line-height:1.5rem;
  }
  h1 {
    font-size: 2.5rem;
    font-weight: 900;
    margin:5px;
    margin-left: 0;
    &:nth-of-type(1) {
      color: #FF78BD;
      font-weight: 700;
    }
    &:nth-of-type(2) {
      color: #FF47A5;
    }
    &:nth-of-type(3) {
      color: #FF0080;
    }
    &:nth-of-type(4) {
      color: #3c0473;
    }
  }
`;

export default LandText;

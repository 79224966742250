import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import { db } from "../../firebase";
import Parser from "html-react-parser/dist/html-react-parser";
import axios from "axios";
import Gist from "react-gist";
import "./FullPage.css"

function FullPage() {
  let { id } = useParams();
  const [data, setData] = useState({});
  const [code, setCode] = useState("");
  useEffect(async () => {
    await db
      .collection("questions")
      .doc(id)
      .get()
      .then((doc) => {
        const docdata = doc.data();
        setData(docdata);

        

        // if (docdata.language === "java") {
        //   axios
        //     .post(
        //       `https://tools.tutorialspoint.com/format_javascript.php`,
        //       data
        //     )
        //     .then((res) => {
        //       setCode(res.data.code);
        //     });
        // } else if (docdata.language === "python") {
        //   //
        //   axios
        //     .post(`https://tools.tutorialspoint.com/format_python.php`, data)
        //     .then((res) => {
        //       setCode(res.data.code);
        //     });
        // }
      });
  }, []);

  return (
    <Container>
      <Wrapper>
        <InnerWrapper>
          <GridItem>
            <h4>{data.question}</h4>
            <h3>Sample Input</h3>
            <Codes>{Parser(`${data.sipt}`)}</Codes>
            <h3>Sample Output</h3>
            <Codes>{Parser(`${data.sout}`)}</Codes>
          </GridItem>
          <GridItem>
            <p>{Parser(`${data.explanation}`)}</p>
          </GridItem>
          
            <GistDiv>
              <Gist id={data.gistid} file={data.code} />
            </GistDiv>
          
          
          
          
        </InnerWrapper>
      </Wrapper>
    </Container>
  );
}

export default FullPage;

const Container = styled.div`
  min-height: 100vh;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`;
const Wrapper = styled.div`
  width: 100vw;
`;
const InnerWrapper = styled.div`
  /* max-width: 1000px; */
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 152px;
  @media (max-width: 768px) {
    margin-top: 132px;
  }
`;

const GridItem = styled.div`
  width: 600px;
  height: 500px;
  background-color: #f9f9f9;
  margin: 5px;
  border-radius: 16px;
  border: 1px solid #dee2e6;
  padding: 20px;
  overflow-y: scroll;

  h4 {
    line-height: 1.5rem;
  }

  p {
    line-height: 1.5rem;
  }
`;

const GistDiv = styled.div`
  width: 600px;
  background-color: #ffffff;
  margin: 5px;
  border-radius: 16px;
  border: 1px solid #dee2e6;
  overflow-y: scroll;
  padding: 20px;
  h4 {
    line-height: 1.5rem;
  }
`;


const CodeDiv = styled.div`
  width: 600px;
  height: 500px;
  background-color: #1e1e1e;
  margin: 5px;
  border-radius: 16px;
  border: 1px solid #dee2e6;
  overflow-y: scroll;
  padding: 20px;
  h4 {
    line-height: 1.5rem;
  }
`;

const Codes = styled.div`
  background-color: rgba(128, 128, 128, 0.25);
  padding: 10px;
  border-radius: 8px;
  min-height: 50px;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5rem;
`;

import React from "react";
import styled from "styled-components";
import image from "../../assets/hero2.png";
import Tilt from "react-tilt";
import LandText from './../../Components/Landing/LandText';

function Landing() {
  return (
    <Container>
      <Wrapper>
        <InnerWrapper>
          <Left>
            <LandText/>
          </Left>
          <TiltWrapper options={{max:25}} >
            <img src={image} alt="" />
          </TiltWrapper>
        </InnerWrapper>
      </Wrapper>
    </Container>
  );
}

export default Landing;

const TiltWrapper = styled(Tilt)`
  width: 60%;
  @media (max-width: 670px) {
      display:none;
  }
`;

const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
  margin: auto;
`;
const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
`;
const InnerWrapper = styled.div`
  max-width: 1000px;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  img {
    width: 100%;
  }
`;
const Left = styled.div`
  width: 40%;
  @media (max-width: 670px) {
    width: 100%;
  }
`;

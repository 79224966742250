import React from "react";
import styled from "styled-components";
import PCard from "./../../Components/ProfileCard/PCard";

function Staff({ staff }) {
  return (
    <Container>
      <Wrapper>
        <InnerWrapper>
          <h1>{staff}</h1>
          <StaffGrid>
            <PCard
              photo="https://csi-backend.s3.us-east-2.amazonaws.com/GARAPATI%20T%20R%20S%20S%20VENKATA%20LIKHITH%2019BCD7245.jpg"
              name="Likhith Garapati"
              position="Web Master"
              links={[
                { linkedin: "https://www.linkedin.com/in/likhith-garapati/", gmail: "likhithgarapati2002@gmail.com",github:"https://github.com/likhith1542" },
              ]}
            />
            {
              staff==='Contributors'?<PCard
              photo="https://csi-backend.s3.us-east-2.amazonaws.com/KODALI%20HEMANTH%20CHOWDARY%2018MIS7023.jpg"
              name="Kodali Hemanth Chowdary"
              position="Technical Department LEAD"
              links={[
                { github:"https://github.com/18mis7023" },
              ]}
            />:null
            }
          </StaffGrid>
        </InnerWrapper>
      </Wrapper>
    </Container>
  );
}

export default Staff;

const Container = styled.div`
  min-height: 100vh;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
  margin: auto;
`;
const Wrapper = styled.div`
  width: 100vw;
`;
const InnerWrapper = styled.div`
  max-width: 1000px;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 0;
  @media (max-width: 768px) {
    margin-top: 0;
  }
`;

const StaffGrid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
`;

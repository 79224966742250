import firebase from "firebase";


const firebaseConfig = {
  apiKey: "AIzaSyBQkWJd7wCw_AqO3nXPO-PsKrYe1sR_U7s",
  authDomain: "maestro-coding.firebaseapp.com",
  projectId: "maestro-coding",
  storageBucket: "maestro-coding.appspot.com",
  messagingSenderId: "84003791891",
  appId: "1:84003791891:web:fd2647cca372d7536f8be6",
};

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

export { db };

import React from "react";
import styled from "styled-components";

function Join() {
  return (
    <Container>
      <Wrapper>
        <InnerWrapper>This is Join Page</InnerWrapper>
      </Wrapper>
    </Container>
  );
}

export default Join;

const Container = styled.div`
  min-height: 100vh;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
  margin: auto;
`;
const Wrapper = styled.div`
  width: 100vw;
`;
const InnerWrapper = styled.div`
  max-width: 1000px;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 152px;
  @media (max-width: 768px) {
    margin-top: 132px;
  }
`;

import Landing from "./Pages/Landing/Landing";
import Header from "./Components/General/Header/Header";
import styled from "styled-components";
import bg from "./assets/bg.jpg";
import Staff from "./Pages/Staff/Staff";
import { BrowserRouter, Route, Switch} from "react-router-dom";
import Question from "./Pages/Questions/Question";
import FullPage from './Pages/Questions/FullPage';
import Join from "./Pages/Join/Join";

function App() {
  return (
    <Container bg={bg}>
      <Wrapper>
        
        <BrowserRouter>
        <Header />
          <Switch>
          <Route path="/join">
              <Join/>
            </Route>
            <Route exact path="/">
              <Landing />
            </Route>
            <Route path="/developers">
              <Staff staff="Developers" />
            </Route>
            <Route path="/contributors">
              <Staff staff="Contributors" />
            </Route>
            <Route path="/questions/:id">
              <FullPage/>
            </Route>
            <Route path="/questions">
              <Question/>
            </Route>
            

          </Switch>
        </BrowserRouter>
      </Wrapper>
    </Container>
  );
}

export default App;

const Container = styled.div`
  background-image: url(${({ bg }) => bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
`;

const Wrapper = styled.div`
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    -webkit-backdrop-filter: blur(35px);
    backdrop-filter: blur(35px);
    background-color: rgba(255, 255, 255, 0.4);
  }
`;

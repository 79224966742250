import React from "react";
import { SiGmail, SiInstagram, SiLinkedin, SiGithub } from "react-icons/si";
import "./PCard.scss";

function PCard(props) {
  return (
    <div className="profile">
      <img className="profile__image" src={props.photo} alt="profileImg" />
      {props.name ? <p className="profile__name">{props.name}</p> : null}
      {props.position ? (
        <p className="profile__position">{props.position}</p>
      ) : null}
      {
        <div className="profile__links">
          {props.links[0]["github"] ? (
            <a
              className="social__link"
              target="_blank"
              rel="noreferrer"
              href={props.links[0]["github"]}
            >
              <SiGithub className="social__icon" />
            </a>
          ) : null}
          {props.links[0]["linkedin"] ? (
            <a
              className="social__link"
              target="_blank"
              rel="noreferrer"
              href={props.links[0]["linkedin"]}
            >
              <SiLinkedin className="social__icon" />
            </a>
          ) : null}
          {props.links[0]["gmail"] ? (
            <a
              className="social__link"
              target="_blank"
              rel="noreferrer"
              href={`mailto:${props.links[0]["gmail"]}`}
            >
              <SiGmail className="social__icon" />
            </a>
          ) : null}
          
        </div>
      }
    </div>
  );
}

export default PCard;
